<template>
  <div id="player" class="player-conteiner" :style="{'width':playerWidth}">
        
    <!-- @ -->
    <div :class="['box-conteiner', 'controls-conteiner']">
      <div class="box-holder">
        <!-- Controls -conteiner -->
        <div class="controls-holder">
          <div id="play-button-conteiner">
            <button @click="togglePlay" v-bind:class="[playing ? 'active' : '', loading ? 'loading' : '']" :key="componentKey">
              <img v-if="!playing" src="../assets/play-button.png" style="width: 100%;height: auto;" />
              <img v-else src="../assets/stop-button.png" style="width: 100%;height: auto;" />
            </button>
          </div>
        </div>
             
        <div v-if="channels.length > 1" id="chanels-conteiner">
          <li v-for="item in channels" :key="item.id">
            <button :disabled="current == item.id" :style="current == item.id?style['channels-button-active']:style['channels-button']" @click="changeChannel(item.id);">{{item.s_format}} {{item.bitrate}} Kbps</button>
          </li>
        </div>
      </div>
    </div>
    
    <!-- @ -->
    <div :class="['box-conteiner', 'cassette-conteiner']">
      <div class="box-holder">
        <img src="../assets/cassette.jpg" style="width:100%; height: auto;" />
        <img src="../assets/tape-hits-sign.png" style="width:100%; height: auto;" />
        <div class="wheel1" v-bind:class="[playing ? 'playing' : '', loading ? 'loading' : '']"><img src="../assets/wheel1.png" style="width:100%; height: auto;" /></div>
        <div class="wheel2" v-bind:class="[playing ? 'playing' : '', loading ? 'loading' : '']"><img src="../assets/wheel1.png" style="width:100%; height: auto;" /></div>
        
      <div class="cassette-holder">
        <!-- Track -->
        <div class="track-holder"><div v-show="playing" class="track-conteiner"><div id="track-title-conteiner">{{track}}</div></div></div>
      
        <!-- Progress Bar -->
        <div class="progress-conteiner" v-show="playing">
          <div class="progress-wrapper" >
            <div v-show="progress.show" id="progressbar" class="progress-bar" ></div>
          </div>
        </div>
        <!--
        <div class="time-conteiner">
          <div v-show="progress.show" class="progress-time" >
          {{progress.time.playback}}/{{progress.time.total}}
          </div>
        </div>
        -->
      </div>
      </div>
          
    </div>
    
    <!-- @ -->
    <div :class="['box-conteiner', 'social-conteiner']">
      <div class="box-holder">
        <div class="social-links">
        <a href="mailto:info@tapehits.com" target="_blank" class="email" title="E-mail">
            <img src="../assets/social/Mail.png"  style="width:100%; height: auto;" />
        </a>
        <a href="https://www.facebook.com/Tape-Hits-113533553704956" target="_blank" class="facebook" title="Facebook">
            <img src="../assets/social/FaceBook.png"  style="width:100%; height: auto;" />
        </a>
        <a href="https://www.instagram.com/tapehits/" target="_blank" class="instagram" title="Instagram">
            <img src="../assets/social/Instagram.png"  style="width:100%; height: auto;" />
        </a>
        <a href="https://t.me/tapehits" target="_blank" class="telegram" title="Telegram">
            <img src="../assets/social/Telegram.png"  style="width:100%; height: auto;" />
        </a>
        <a href="https://vk.com/public195405831" target="_blank" class="vkontakte" title="Vkontakte">
            <img src="../assets/social/VK.png"  style="width:100%; height: auto;" />
        </a>
        <a href="https://www.paypal.com/donate?hosted_button_id=TX79YQ2NVLB6Q" target="_blank" class="donate" title="Donate">
            <img src="../assets/social/Donate.png"  style="width:100%; height: auto;" />
        </a>
        
        <vue-material-slider v-if="!isMobile()"
            :min="0"
            :max="100"
            :vertical="false"
            :stepSize="0.1"
            :thumbLabel="false"      
            v-model="volume"
          />
        
        </div>
      </div>
    </div>
    <div id="background">
<span>CORONA</span>
<span>2 BROTHERS ON THE 4TH FLOOR</span>
<span>DJ BOBO</span>
<span>ICE MC</span>
<span>ME & MY</span>
<span>DR. ALBAN</span>
<span>AKCENT</span>
<span>ATC</span>
<span>DUNE</span>
<span>PINK</span>
<span>CULTURE BEAT</span>
<span>JENIFFER LOPEZ</span>
<span>VENGABOYS</span>
<span>MO-DO</span>
<span>STING</span>
<span>SCOOTER</span>
<span>ALEXIA</span>
<span>MICHAEL JACKSON</span>
<span>ENRIQUE IGLESIAS</span>
<span>666</span>
<span>RICKY MARTIN</span>
<span>E-ROTIC</span>
<span>FAITHLESS</span>
<span>ATB</span>
<span>CAPTAIN JACK</span>
<span>CHER</span>
<span>LA BOUCHE</span>
<span>BACKSTREET BOYS</span>
<span>MR. PRESIDENT</span>
<span>A1</span>
<span>AQUA</span>
<span>FALCO</span>
<span>KLF</span>
<span>E-TYPE</span>
<span>MODJO</span>
<span>EAST 17</span>
<span>U2</span>
<span>DOUBLE YOU</span>
<span>KYLIE MINOGUE</span>
<span>TLC</span>     
<span>LOFT</span>
<span>MADONNA</span>
<span>HIM</span>
<span>BASIC ELEMENT</span>
<span>U96</span>
<span>ACE OF BASE</span>
<span>REDNEX</span>
<span>SHAGGY</span>
<span>EMINEM</span>
<span>COOLIO</span>
<span>O-ZONE</span>
<span>EIFFEL 65</span>
<span>TARKAN</span>
<span>GALA</span>
<span>FUN FACTORY</span>
<span>NEK</span>
<span>REAL McCOY</span>
<span>MASTERBOY</span>
<span>SNAP!</span>
<span>SCATMAN JOHN</span>
<span>DIDO</span>
<span>SASH!</span>
<span>CAPPELLA</span>
<span>2 UNLIMITED</span>
<span>LOU BEGA</span>
<span>SPICE GIRLS</span>
<span>ENIGMA</span>
<span>DAS MODUL</span>
<span>GIGI D‘AGOSTINO</span>
<span>CRAZY FROG</span>
<span>GEORGE MICHAEL</span>
<span>N SYNC</span>
<span>HADDAWAY</span>
<span>DAFT PUNK</span>
<span>TECHNOTRONIC</span>
<span>TWENTY 4 SEVEN</span>
<span>FIVE</span>
<span>FATBOY SLIM</span>
<span>MOBY</span>
<span>BROOKLYN BOUNCE</span>
<span>BRITNEY SPEARS</span>
<span>CAPTAIN HOLLYWOOD PROJECT</span>

<span>CORONA</span>
<span>2 BROTHERS ON THE 4TH FLOOR</span>
<span>DJ BOBO</span>
<span>ICE MC</span>
<span>ME & MY</span>
<span>DR. ALBAN</span>
<span>AKCENT</span>
<span>ATC</span>
<span>DUNE</span>
<span>PINK</span>
<span>CULTURE BEAT</span>
<span>JENIFFER LOPEZ</span>
<span>VENGABOYS</span>
<span>MO-DO</span>
<span>STING</span>
<span>SCOOTER</span>
<span>ALEXIA</span>
<span>MICHAEL JACKSON</span>
<span>ENRIQUE IGLESIAS</span>
<span>666</span>
<span>RICKY MARTIN</span>
<span>E-ROTIC</span>
<span>FAITHLESS</span>
<span>ATB</span>
<span>CAPTAIN JACK</span>
<span>CHER</span>
<span>LA BOUCHE</span>
<span>BACKSTREET BOYS</span>
<span>MR. PRESIDENT</span>
<span>A1</span>
<span>AQUA</span>
<span>FALCO</span>
<span>KLF</span>
<span>E-TYPE</span>
<span>MODJO</span>
<span>EAST 17</span>
<span>U2</span>
<span>DOUBLE YOU</span>
<span>KYLIE MINOGUE</span>
<span>TLC</span>     
<span>LOFT</span>
<span>MADONNA</span>
<span>HIM</span>
<span>BASIC ELEMENT</span>
<span>U96</span>
<span>ACE OF BASE</span>
<span>REDNEX</span>
<span>SHAGGY</span>
<span>EMINEM</span>
<span>COOLIO</span>
<span>O-ZONE</span>
<span>EIFFEL 65</span>
<span>TARKAN</span>
<span>GALA</span>
<span>FUN FACTORY</span>
<span>NEK</span>
<span>REAL McCOY</span>
<span>MASTERBOY</span>
<span>SNAP!</span>
<span>SCATMAN JOHN</span>
<span>DIDO</span>
<span>SASH!</span>
<span>CAPPELLA</span>
<span>2 UNLIMITED</span>
<span>LOU BEGA</span>
<span>SPICE GIRLS</span>
<span>ENIGMA</span>
<span>DAS MODUL</span>
<span>GIGI D‘AGOSTINO</span>
<span>CRAZY FROG</span>
<span>GEORGE MICHAEL</span>
<span>N SYNC</span>
<span>HADDAWAY</span>
<span>DAFT PUNK</span>
<span>TECHNOTRONIC</span>
<span>TWENTY 4 SEVEN</span>
<span>FIVE</span>
<span>FATBOY SLIM</span>
<span>MOBY</span>
<span>BROOKLYN BOUNCE</span>
<span>BRITNEY SPEARS</span>
<span>CAPTAIN HOLLYWOOD PROJECT</span>

<span>CORONA</span>
<span>2 BROTHERS ON THE 4TH FLOOR</span>
<span>DJ BOBO</span>
<span>ICE MC</span>
<span>ME & MY</span>
<span>DR. ALBAN</span>
<span>AKCENT</span>
<span>ATC</span>
<span>DUNE</span>
<span>PINK</span>
<span>CULTURE BEAT</span>
<span>JENIFFER LOPEZ</span>
<span>VENGABOYS</span>
<span>MO-DO</span>
<span>STING</span>
<span>SCOOTER</span>
<span>ALEXIA</span>
<span>MICHAEL JACKSON</span>
<span>ENRIQUE IGLESIAS</span>
<span>666</span>
<span>RICKY MARTIN</span>
<span>E-ROTIC</span>
<span>FAITHLESS</span>
<span>ATB</span>
<span>CAPTAIN JACK</span>
<span>CHER</span>
<span>LA BOUCHE</span>
<span>BACKSTREET BOYS</span>
<span>MR. PRESIDENT</span>
<span>A1</span>
<span>AQUA</span>
<span>FALCO</span>
<span>KLF</span>
<span>E-TYPE</span>
<span>MODJO</span>
<span>EAST 17</span>
<span>U2</span>
<span>DOUBLE YOU</span>
<span>KYLIE MINOGUE</span>
<span>TLC</span>     
<span>LOFT</span>
<span>MADONNA</span>
<span>HIM</span>
<span>BASIC ELEMENT</span>
<span>U96</span>
<span>ACE OF BASE</span>
<span>REDNEX</span>
<span>SHAGGY</span>
<span>EMINEM</span>
<span>COOLIO</span>
<span>O-ZONE</span>
<span>EIFFEL 65</span>
<span>TARKAN</span>
<span>GALA</span>
<span>FUN FACTORY</span>
<span>NEK</span>
<span>REAL McCOY</span>
<span>MASTERBOY</span>
<span>SNAP!</span>
<span>SCATMAN JOHN</span>
<span>DIDO</span>
<span>SASH!</span>
<span>CAPPELLA</span>
<span>2 UNLIMITED</span>
<span>LOU BEGA</span>
<span>SPICE GIRLS</span>
<span>ENIGMA</span>
<span>DAS MODUL</span>
<span>GIGI D‘AGOSTINO</span>
<span>CRAZY FROG</span>
<span>GEORGE MICHAEL</span>
<span>N SYNC</span>
<span>HADDAWAY</span>
<span>DAFT PUNK</span>
<span>TECHNOTRONIC</span>
<span>TWENTY 4 SEVEN</span>
<span>FIVE</span>
<span>FATBOY SLIM</span>
<span>MOBY</span>
<span>BROOKLYN BOUNCE</span>
<span>BRITNEY SPEARS</span>
<span>CAPTAIN HOLLYWOOD PROJECT</span>

<span>CORONA</span>
<span>2 BROTHERS ON THE 4TH FLOOR</span>
<span>DJ BOBO</span>
<span>ICE MC</span>
<span>ME & MY</span>
<span>DR. ALBAN</span>
<span>AKCENT</span>
<span>ATC</span>
<span>DUNE</span>
<span>PINK</span>
<span>CULTURE BEAT</span>
<span>JENIFFER LOPEZ</span>
<span>VENGABOYS</span>
<span>MO-DO</span>
<span>STING</span>
<span>SCOOTER</span>
<span>ALEXIA</span>
<span>MICHAEL JACKSON</span>
<span>ENRIQUE IGLESIAS</span>
<span>666</span>
<span>RICKY MARTIN</span>
<span>E-ROTIC</span>
<span>FAITHLESS</span>
<span>ATB</span>
<span>CAPTAIN JACK</span>
<span>CHER</span>
<span>LA BOUCHE</span>
<span>BACKSTREET BOYS</span>
<span>MR. PRESIDENT</span>
<span>A1</span>
<span>AQUA</span>
<span>FALCO</span>
<span>KLF</span>
<span>E-TYPE</span>
<span>MODJO</span>
<span>EAST 17</span>
<span>U2</span>
<span>DOUBLE YOU</span>
<span>KYLIE MINOGUE</span>
<span>TLC</span>     
<span>LOFT</span>
<span>MADONNA</span>
<span>HIM</span>
<span>BASIC ELEMENT</span>
<span>U96</span>
<span>ACE OF BASE</span>
<span>REDNEX</span>
<span>SHAGGY</span>
<span>EMINEM</span>
<span>COOLIO</span>
<span>O-ZONE</span>
<span>EIFFEL 65</span>
<span>TARKAN</span>
<span>GALA</span>
<span>FUN FACTORY</span>
<span>NEK</span>
<span>REAL McCOY</span>
<span>MASTERBOY</span>
<span>SNAP!</span>
<span>SCATMAN JOHN</span>
<span>DIDO</span>
<span>SASH!</span>
<span>CAPPELLA</span>
<span>2 UNLIMITED</span>
<span>LOU BEGA</span>
<span>SPICE GIRLS</span>
<span>ENIGMA</span>
<span>DAS MODUL</span>
<span>GIGI D‘AGOSTINO</span>
<span>CRAZY FROG</span>
<span>GEORGE MICHAEL</span>
<span>N SYNC</span>
<span>HADDAWAY</span>
<span>DAFT PUNK</span>
<span>TECHNOTRONIC</span>
<span>TWENTY 4 SEVEN</span>
<span>FIVE</span>
<span>FATBOY SLIM</span>
<span>MOBY</span>
<span>BROOKLYN BOUNCE</span>
<span>BRITNEY SPEARS</span>
<span>CAPTAIN HOLLYWOOD PROJECT</span>

    </div>
  </div>
</template>

<script>

import Vue from 'vue'

import _channels from './js/channels';

import {Howl, Howler} from 'howler';

import 'vue-material-slider/dist/vue-material-slider.css';
import VueMaterialSlider from 'vue-material-slider';
Vue.use(VueMaterialSlider);

export default {
    
  data: function() {
    return{
      init: false,
      playing: false,
      loading: false,
      url: '',
      volume: 70.0,
      errors: {},
      channels: [],
      channel: {},
      track: '',
      limit: 1,
      history:{},
      timeStart: 0,
      timeDisplay: '00:00:00',
      timeItv: null,
      current:null,
      style:[],
      progress: {
        show: false,
        init: null,
        // played percent
        played: 0,
        // time hh:mm:ss
        time: {
          playback: '',
          total: ''
        }
      },
       componentKey: 0,
       stream: null,
    }
  },
  props: {
       
    apiUrl: {
      type: String,
      required: true,
    },
    
    serverId: {
      type: String,
      required: true,
    },
    
    lang: {
      type: String,
      default: 'ru',
    },
    
    stationName: {
      type:String,
      required: true,
    },
    
    stationUrl: {
      type:String,
      required: false,
    },
    
    // Boxes
    playerWidth: {
      type: String,
      default: '100%', 
    },
    
    // progtress bar section
    progressShow: {
      type:Boolean,
      default: true
    },
  },
    
  computed: {
   
  },
  
  watch: {
    
    // when track changed
    track(){
      
      this.history = _channels.historyData;
      //this.lastTrack = _channels.historyData[0];
      
      // TODO: formatt all data after axois fetch and remove this construction
      this.history.forEach(function(item, i) {
        this.history[i].ls = this.formatTime(item.ts, true);
      }, this);
        
    },
    
    // watch playing status
    playing() {
      if ( this.playing ) { this.startClock(); }
      else { this.stopClock(); }
    },
    
    // update player volume
    volume() {
      Howler.volume(this.volume/100);
    }
    
  },
  
  created: function() {
    
    // 100% volume for mobile
    if(this.isMobile()) this.volume = 100;    
  }, 
  
  mounted() {
    
    // update urls from props
    _channels.apiUrl   = this.apiUrl;
    _channels.serverId = this.serverId;
    
    // setup audio
    this.setupAudio();
  
  },
  
  destroyed() {
    
    // remove audio
    this.closeAudio();
    
  },
  
  methods: {
    
    // run maintenance tasks on a timer
    setupMaintenance() {
      setTimeout(() => {
        this.itv = setInterval( () => {
          if(!Object.keys(this.channel).length)
            this.getChannels(); // update channels
          this.getSongs(); // update channel tracks
        }, 1000 * 10 );
      }, 1000 * 10);
    },
    
    // get channels data from api
    getChannels() {
      return new Promise((resolve, reject) => {
        _channels.getChannels( ( err, channels ) => {
          if ( err ){
            reject(err);
            return this.setError( 'channels', err );
          }
         
          for (let key = 0; key < channels.length; key++ ) {
            if(!channels[key].active) channels.splice(key, 1);
          }
         
          this.channels = channels;
          this.clearError( 'channels' );
          this.updateCurrentChannel();
  
          resolve(channels);
        });
      });
    },

    // get current song for a channel from api
    getSongs() {
      return new Promise((resolve, reject) => {
        _channels.getSongs(( err, track ) => {
          if ( err ){
            reject(err);
            return this.setError( 'songs', err );
          }
          this.track = track;
          this.clearError( 'songs' );
          resolve(track);
        }, this.limit);
      });
    },

    // checks is a channel is currently selected
    isCurrentChannel( channel ) {
        
      // set default
      if(!this.channel.id )return true; 

      if ( !channel || !channel.id) return false;
      if ( this.channel.id !== channel.id ) return false;
      return true;
    },

    // update data for current selected channel
    updateCurrentChannel() {
      for ( let c of this.channels ) {
        // see if channel is currently selected
        if ( this.isCurrentChannel( c ) ) {
          this.current = c.id;
          c.current = true;
          this.channel = Object.assign( this.channel, c );
          //this.url = this.channel.stream_url;
          this.url = this.channel.secure_stream_url
      
        }else{
            c.current = false;
        }
      }
    },
    
    // change channel by user
    changeChannel(id){
      this.channel.id = id;
        
      if(this.playing){
        this.closeAudio();
        this.updateCurrentChannel();
        this.playAudioStream( /*this.url*/ );
      }else       
        this.updateCurrentChannel();
        
    },
    
    // play or pause on click 
    togglePlay() {
      if ( this.loading ) return;
      if ( this.playing ){
        //if(this.loading) this.loading =  false;
        return this.closeAudio();
      }
      return this.playAudioStream();
    },
    
    // create audio and context objects
    async setupAudio() {
      
      await this.getChannels();
      await this.getSongs();
      
      this.setupMaintenance();
      
      this.init = true;
    },
    
    // close active audio
    closeAudio() {
      //_audio.stopAudio();
      if(this.stream) this.stream.stop();
      this.playing = false;
      
      // stop progress bar
      clearInterval(this.progress.init);
      this.progress.show = false;
    },
    
    // wrapper for loading and playing a new audio stream source
    playAudioStream(  ) {
      this.flushErrors();
      this.loading = true; // spinner
      
      if(!this.stream || this.stream._src != this.url){
        let self = this;
        this.stream =  new Howl({
          src: this.url,
          html5: true,
          format: ['mp3'],
          onplay: function() {
            self.loading = false;
            self.playing = true;
          }
        });
      }
      
      this.stream.play();
      
      if(this.progressShow){
        this.progress.init = setInterval(() => {
          this.updateProgress();
        }, 1000);
      }
    },
    
    // on keyboard events
    onKeyboard( e ) {
      const k = e.key || '';
      if ( k === ' ') return this.togglePlay();
    },
    
    // set an erro message
    setError( key, err ) {
      let errors = Object.assign( {}, this.errors );
      errors[ key ] = String( err || '' ).trim();
      if ( err ) console.warn( 'ERROR('+ key +'):', err );
      this.errors = errors;
    },

    // clear all error messages
    clearError( key ) {
      let errors = Object.assign( {}, this.errors );
      delete errors[ key ];
      this.errors = errors;
    },

    // check if an error has been set for a key
    hasError( key ) {
      return ( key && Object.prototype.hasOwnProperty.call(this.errors, key ) );
    },
    
    // flush all errors
    flushErrors() {
      this.errors = {};
    },
    
    // start tracking playback time
    startClock() {
      this.stopClock();
      this.timeStart = Date.now();
      this.timeItv = setInterval( this.updateClock, 1000 );
      this.updateClock();
    },

    // update tracking playback time
    updateClock() {
      let p = n => ( n < 10 ) ? '0'+n : ''+n;
      let elapsed = ( Date.now() - this.timeStart ) / 1000;
      let seconds = Math.floor( elapsed % 60 );
      let minutes = Math.floor( elapsed / 60 % 60 );
      let hours   = Math.floor( elapsed / 3600 );
      this.timeDisplay = p( hours ) +':'+ p( minutes ) +':'+ p( seconds );
    },
    
    // clear timer refs
    clearTimers() {
      if ( this.sto ) clearTimeout( this.sto );
      if ( this.itv ) clearInterval( this.itv );
      //if ( this.anf ) cancelAnimationFrame( this.anf );
    },

    // stop tracking playback time
    stopClock() {
      if ( this.timeItv ) clearInterval( this.timeItv );
      this.timeItv = null;
    },
    
    updateProgress() {
      if(this.loading == true) return;
      
      // get data from channal
      let lastTrack = this.history[0];
      
      if (typeof lastTrack.ts !== 'undefined' && lastTrack.ts) {  
        this.progress.show = true;
        
        let timeFromStart = (+ new Date()) - lastTrack.ts;
        if (timeFromStart > lastTrack.length) {
          timeFromStart = lastTrack.length;
        }
        
        this.progress.time.playback = this.formatTime(timeFromStart);
        this.progress.time.total    = this.formatTime(lastTrack.length);
        
        this.progress.played = (timeFromStart / lastTrack.length) * 100;
        if (this.progress.played >= 100) this.progress.played = 100; 
        
        document.getElementById('progressbar').style.width = this.progress.played+'%'; 
      }
      else {
        this.progress.show = false;
      }
    },
    
    // convert microtime to hh:mm:ss
    formatTime(microtime, local = false){
      if(local) return  new Date(microtime).toLocaleString().substr(12, 8);
      return new Date(microtime).toISOString().substr(11, 8);
    },

    // hex to rgb convertation
    hexToRgb(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    isMobile() {
      /* eslint-disable */  
      var check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
        check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    isSafari(){
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }
  },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
</style>

<style>
.player-conteiner{
    max-width:100%;
    position:relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex-wrap: wrap;
    margin: auto;
    height:100%;
    box-sizing: border-box;
}
.controls-conteiner{
    order: 1;
    width:25%;
    display: flex;
    align-items: center;
}
.cassette-conteiner{
    order: 2;
    background-size:contain;
    background-repeat:no-repeat; 
    width:50%;
    display: flex;
    align-items: center;
}
.social-conteiner{
    order:3;
    width:25%;
    display: flex;
    align-items: center;   
}
.box-conteiner .box-holder{
    position:relative;
    width:100%;
    z-index: 1;
}
.cassette-holder{
    position:absolute;
    margin-left: 16.5%;
    width: 68.1%;
    margin-top: 9%;
    height:9%;
    display: flex;
    flex-direction: column;
    top:0;
    left:0;
}
.cassette-holder > div{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.cassette-conteiner .box-holder .wheel1{
    position:absolute;
    left:18%;
    width:22%;
    top:23%;
   
    -webkit-animation:spin 5s linear infinite;
    -moz-animation:spin 5s linear infinite;
    animation:spin 5s linear infinite;
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    animation-play-state: paused;
}
.cassette-conteiner .box-holder  .wheel2{
    position:absolute;
    left:60%;
    width:21.8%;
    top:23%;
    -webkit-animation:spin 5s linear infinite;
    -moz-animation:spin 5s linear infinite;
    animation:spin 5s linear infinite;   
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    animation-play-state: paused; 
}
.cassette-conteiner .box-holder .wheel1.playing,
.cassette-conteiner .box-holder .wheel2.playing{
     
    -webkit-animation-play-state: running;
    -moz-animation-play-state: running;
    animation-play-state: running;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.cassette-conteiner .box-holder .wheel1 img,
.cassette-conteiner .box-holder  .wheel2 img{
    display:block;
}

/******* ProgressBar  *******/
.progress-conteiner{
}
#progressbar{
    transition: width 1s;
    background-color: #40b280;
    height:2px;
}
.progress-wrapper{
    position:relative;
    width: 100%;
    border-style: solid;
    border-width: 0;
    overflow: hidden;
    background-color: #e0e0e0;
    height:16%;
    display:flex;
    align-items: center;
}
.progress-time{
    font-size: 80%;
}

/*****************************************************/

.controls-holder{
    position:relative;
    text-align: center;
    width:40%;
    background: #FFF;
    box-sizing: content-box;
    border: 2vw #fff solid;
    margin-left: 10%;
    border-radius: 100%;
   
}

#play-button-conteiner{
    position:relative;
    display: flex;
    align-items: center;
}
#play-button-conteiner button{
    border-radius: 100%;
    border: 0;
    margin: auto;
    outline:none;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    cursor:pointer;
    transition: background-image 0.2s ease-in-out;
    padding:0;
    width:100%;
    height:auto;
    position:relative;
}
#play-button-conteiner button img{
    display:block;
}

/* Loader*/

#play-button-conteiner button.loading:after {
    content: " ";
    display: block;
    border-radius: 50%;
    border: 0.9vw solid #000;
    position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-color: #000 transparent #000 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cassette-holder{
    /*font-family: Courier New;*/
    text-align: left;
    font-family:Lucida Console, Courier,Menlo;
}
.track-holder{
    min-height: 4.5%;
}
#track-title-conteiner{
    text-overflow:ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1.5vw;
}
.track-conteiner a{
    white-space: nowrap;
    /*color:#35495e;*/
}
.slider-holder{

}
.slider{
    border-width:0px;
    border-style:solid;
    border-radius: 0px;
    margin-top:0;
    background:rgba(255,255,255,1);
    z-index:10;
    min-width: 70px;
    position: absolute;
    left: -2vw;
    right: -2vw;
}
.slider-horizontal .slider-wrapper {
    height: 2px;
    top: 23px;
    left: 2vw;
    right: 2vw;
}
.slider-thumb{
    border-radius: 0;
    width: 8px;
    right: -5px;
}
.slider-thumb, .slider-thumb-label, .slider-track-fill {
    background-color: #333;
}

#chanels-conteiner{
    /*display:none;*/
}
#chanels-conteiner li{
    list-style:none;
    display:inline-block;
    margin-right: 10px;
}

.social-links{
    background: #FFF;
    box-sizing: content-box;
    border: 2vw #fff solid;
    padding-right: 5%;
    float:right;
    position:relative;    
}
.social-links a{
    width:3.6vw;
    height:3.6vw;
    display:block;
    background-size:contain;
    background-repeat:no-repeat;
    margin: 0.5vw 0;    
}
.social-links a img{
    display:block;
    image-rendering: crisp-edges;
}
.social-links a.email{
   
}
.social-links a.facebook{
    
}
.social-links a.instagram{
   
}
.social-links a.telegram{
   
}
.social-links a.vkontakte{
    
}
.social-links a.donate{
  
}

#background{
    position: absolute;
    z-index:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #e9e9e9;
    max-width:100%;
    max-height:100%;
    overflow:hidden;
    font-size: 1.2vw;
    font-weight:600;
    font-family: 'Montserrat', sans-serif;
}
#background span{
    display: inline-block;
    margin: 0.3vw 0.3vw;
    padding: 0.4vw;
    white-space: nowrap;
    
    
}

@media screen and (max-width: 700px) {
  .player-conteiner{
    align-items: center;
  }
  .cassette-conteiner{
    order: 0;
    width:100%;
  }
  .controls-conteiner{
    width:100%;
  }
  .controls-holder{
    padding:0;
    margin: auto;
    border: 4vw #fff solid;
  }
  #track-title-conteiner {
    font-size: 3.0vw;
  }
  .social-conteiner{
    width:100%;
  }
  .social-links{
    margin:auto;
    float:none;
    padding:0;
    display: flex;
    justify-content: center;
  }
  .social-links a{
    display:inline-block;
    width:10%;
    height: auto;
    margin: 0.5%;
  }
  .slider{
    display:none;
  }
  #background{
    font-size: 1.2vh;
  }
}
</style>