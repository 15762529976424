import axios from 'axios'

export default {
  
  apiUrl:null,
  
  serverId:null,
  
  chanelsData:{},
  
  historyData:[], 
  
  // get channels data from api
  getChannels( callback ) {
    const apiurl = this.apiUrl+'/channels/?server='+this.serverId; 
    const error  = 'There was a problem fetching the latest list of music channels.';

    axios.get( apiurl ).then( res => {
      this.chanelsData = res.data;
      if ( !Object.keys(this.chanelsData).length ) return callback( error, [] );
      
      return callback( null, this.chanelsData );
    })
    .catch( e => {
      return callback( error + String( e.message || '' ), [] );
    });
  },

  // fetch song for a channel
  getSongs( callback, limit ) {
    const error  = 'There was a problem fetching the current channel track.';
    if(!this.apiUrl) return (null, null);
    const apiurl = this.apiUrl+'/history/?limit='+limit+'&offset=0&server='+this.serverId;

    axios.get( apiurl ).then( res => {
      this.historyData = res.data.results;
      //console.log(this.historyData);
      
      if ( typeof res.data.results[0].metadata === 'undefined' ) return callback( null, 'Unknown artist - unknown track') 
      return callback( null, res.data.results[0].metadata);
    })
    .catch( e => {
      return callback( error + String( e.message || '' ), [] );
    });
  },
  
  // set vote by user
  setVote( callback, vote) {
    let error  = 'There was a problem with setting vote.';
    if(!this.apiUrl) return (null, null);
    const apiurl = this.apiUrl+'/music/'+this.historyData[0].all_music_id+'/' + ( vote ? 'like/' : 'dislike/');

    axios.post( apiurl ).then( res => {
      let votes = [];
      if (res.data){
        votes['up']   = res.data.up;
        votes['down'] = res.data.down;
      } 
      return callback( null, votes);
    })
    .catch( e => {
        if (e.response.data && e.response.data.result === "already_voted") {
          error = 'Already voted!';
        }
      return callback( error + String( e.message || '' ), [] );
    });
  },
 
 }