<template>
  <div id="app">
    <Player api-url="https://tapehits.com:8080/api/v2" server-id="1" lang="ru" station-name="TapeHits" :showImage="false" :showHistory="false" station-url="https://tapehits.com" v-bind:share="['facebook','vk','twitter','odnoklassniki','tumblr']" share-url="https://tapehits.com"></Player>  
  </div>
</template>

<script>
import Player from './components/player.vue'

export default {
  name: 'App',
  components: {
    Player
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height:100%;
  padding: 8px;
  box-sizing: border-box;
}
body{
    margin:0;
    height:100vh;
}
</style>
